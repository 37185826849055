<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="bg-white rounded-8">
      <div class="p-16 border-bottom">
        <div class="row gx-16 mb-8">
          <div class="col-sm-3 col-xl-4">
            <GradeSelectField label="Kelas" v-model="filter.grade_id" />
          </div>
          <div class="col-sm-3 col-xl-4 mt-16 mt-sm-0">
            <SubjectSelectField label="Pelajaran" v-model="filter.subject_id" />
          </div>
          <div class="col-sm-6 col-xl-4 mt-16 mt-sm-0">
            <DateField
              label="Tanggal"
              v-model="filter.date"
              :vue2Datepicker="{
                defaultValue: defaultFilterDate,
                disabledDate: notAfterToday,
                format: 'YYYY-MM-DD',
                range: true,
                valueType: 'format',
              }"
            />
          </div>
        </div>
      </div>

      <div class="text-sm-center p-16" v-if="!filterSelected">
        Silakan pilih kelas, pelajaran dan tanggal terlebih dahulu.
      </div>

      <div class="is-loading is-loading-primary p-16" v-else-if="isFetching">
        &nbsp;
      </div>

      <div class="table-responsive" v-else-if="report">
        <table class="table align-middle">
          <thead>
            <tr>
              <th>Siswa</th>
              <th>NISN</th>
              <th
                class="text-center"
                v-for="meeting of report.meetings"
                :key="meeting.id"
              >
                {{ dayjs(meeting.date).format('D') }}
                <div class="fs-11 fw-normal text-muted text-uppercase">
                  {{ dayjs(meeting.date).format('MMM') }}
                </div>
              </th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="student of report.students" :key="student.nisn">
              <td>{{ student.user.name }}</td>
              <td>{{ student.nisn }}</td>
              <td
                class="text-center"
                v-for="meeting of report.meetings"
                :key="meeting.id"
              >
                <CheckIcon
                  class="text-success"
                  v-if="
                    student.attendances.some(
                      ({ meeting_id }) => meeting_id === meeting.id
                    )
                  "
                />
                <XIcon class="text-danger" v-else />
              </td>
              <td class="text-center">{{ student.attendances_count }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2">Total</th>
              <td
                class="text-center"
                v-for="meeting of report.meetings"
                :key="meeting.id"
              >
                {{ meeting.attendances_count }}
              </td>
              <td class="text-center">
                {{
                  report.meetings
                    .map(({ attendances_count }) => attendances_count)
                    .reduce((total, count) => total + count, 0)
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import api from '../../../api';
import dayjs from '../../../bootstrap/dayjs';

import DateField from '../../../components/form/DateField.vue';
import GradeSelectField from '../../../components/grade/GradeSelectField.vue';
import SubjectSelectField from '../../../components/subject/SubjectSelectField.vue';

import CheckIcon from 'bootstrap-icons/icons/check.svg';
import XIcon from 'bootstrap-icons/icons/x.svg';

export default {
  components: {
    DateField,
    GradeSelectField,
    SubjectSelectField,
    CheckIcon,
    XIcon,
  },

  data() {
    return {
      filter: this.parseFilter(cloneDeep(this.$route.query.filter || {})),
      isFetching: false,
      report: null,
    };
  },

  computed: {
    defaultFilterDate() {
      return [dayjs().subtract(1, 'month').toDate(), new Date()];
    },

    filterSelected() {
      return !['grade_id', 'subject_id', 'date'].some(
        (filter) => !this.filter[filter]
      );
    },
  },

  methods: {
    dayjs,

    async fetch() {
      if (!this.filterSelected) return;

      try {
        this.isFetching = true;
        this.report = await api
          .get('v1/reports/attendances', { params: { filter: this.filter } })
          .then(({ data }) => data.data);
      } catch (error) {
        this.$alert.requestError(error);
      } finally {
        this.isFetching = false;
      }
    },

    notAfterToday(date) {
      return date > dayjs().endOf('day').toDate();
    },

    parseFilter(filter) {
      return Object.entries(filter).reduce((result, [key, value]) => {
        switch (key) {
          case 'grade_id':
          case 'subject_id':
            result[key] = Number(value) || null;
            break;
          default:
            result[key] = value;
            break;
        }
        return result;
      }, {});
    },
  },

  metaInfo: {
    title: 'Rekap Presensi',
  },

  watch: {
    filter: {
      handler(filter) {
        const query = { ...this.$route.query, filter };

        this.$router.replace({ query });
        this.fetch();
      },
      deep: true,
    },

    filterSelected(filterSelected) {
      if (!filterSelected) {
        this.report = null;
      }
    },
  },

  beforeMount() {
    this.fetch();
  },
};
</script>
