var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"container pb-16 pb-lg-32 px-lg-32"},[_c('div',{staticClass:"bg-white rounded-8"},[_c('div',{staticClass:"p-16 border-bottom"},[_c('div',{staticClass:"row gx-16 mb-8"},[_c('div',{staticClass:"col-sm-3 col-xl-4"},[_c('GradeSelectField',{attrs:{"label":"Kelas"},model:{value:(_vm.filter.grade_id),callback:function ($$v) {_vm.$set(_vm.filter, "grade_id", $$v)},expression:"filter.grade_id"}})],1),_c('div',{staticClass:"col-sm-3 col-xl-4 mt-16 mt-sm-0"},[_c('SubjectSelectField',{attrs:{"label":"Pelajaran"},model:{value:(_vm.filter.subject_id),callback:function ($$v) {_vm.$set(_vm.filter, "subject_id", $$v)},expression:"filter.subject_id"}})],1),_c('div',{staticClass:"col-sm-6 col-xl-4 mt-16 mt-sm-0"},[_c('DateField',{attrs:{"label":"Tanggal","vue2Datepicker":{
              defaultValue: _vm.defaultFilterDate,
              disabledDate: _vm.notAfterToday,
              format: 'YYYY-MM-DD',
              range: true,
              valueType: 'format',
            }},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1)])]),(!_vm.filterSelected)?_c('div',{staticClass:"text-sm-center p-16"},[_vm._v(" Silakan pilih kelas, pelajaran dan tanggal terlebih dahulu. ")]):(_vm.isFetching)?_c('div',{staticClass:"is-loading is-loading-primary p-16"},[_vm._v("   ")]):(_vm.report)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table align-middle"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Siswa")]),_c('th',[_vm._v("NISN")]),_vm._l((_vm.report.meetings),function(meeting){return _c('th',{key:meeting.id,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.dayjs(meeting.date).format('D'))+" "),_c('div',{staticClass:"fs-11 fw-normal text-muted text-uppercase"},[_vm._v(" "+_vm._s(_vm.dayjs(meeting.date).format('MMM'))+" ")])])}),_c('th',{staticClass:"text-center"},[_vm._v("Total")])],2)]),_c('tbody',_vm._l((_vm.report.students),function(student){return _c('tr',{key:student.nisn},[_c('td',[_vm._v(_vm._s(student.user.name))]),_c('td',[_vm._v(_vm._s(student.nisn))]),_vm._l((_vm.report.meetings),function(meeting){return _c('td',{key:meeting.id,staticClass:"text-center"},[(
                  student.attendances.some(
                    function (ref) {
                                var meeting_id = ref.meeting_id;

                                return meeting_id === meeting.id;
            }
                  )
                )?_c('CheckIcon',{staticClass:"text-success"}):_c('XIcon',{staticClass:"text-danger"})],1)}),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(student.attendances_count))])],2)}),0),_c('tfoot',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v("Total")]),_vm._l((_vm.report.meetings),function(meeting){return _c('td',{key:meeting.id,staticClass:"text-center"},[_vm._v(" "+_vm._s(meeting.attendances_count)+" ")])}),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.report.meetings .map(function (ref) {
                            var attendances_count = ref.attendances_count;

                            return attendances_count;
}) .reduce(function (total, count) { return total + count; }, 0))+" ")])],2)])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }